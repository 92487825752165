<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="customercompanies"
      :items-per-page="25"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8 text-right"
          >
            <v-btn
              v-if="isAllowVerification"
              color="purple"
              dark
              class="mb-2"
              @click="verificationButtonClick"
            >
              VERIFIKASI
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.domisili`]="{ item }">
        <span>{{ item.akot_string }}, {{ item.apro_string }}</span>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="isAllowDetail"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-btn
          small
          dark
          color="purple"
          class="mr-2"
          @click="detailButtonClick(item)"
        >
          DETAIL
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      headers: [
        { text: 'Nama Badan Usaha', align: 'start', value: 'name_legal' },
        { text: 'Nama Merek', align: 'start', value: 'name' },
        { text: 'Pemilik', value: 'owner' },
        { text: 'Domisili', value: 'domisili', sortable: true },
        // { text: 'Kota', value: 'akot_string' },
        // { text: 'Provinsi', value: 'apro_string', sortable: false },
        // { text: 'Email', value: 'email', sortable: false },
        { text: 'Telepon', value: 'phone', sortable: false },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      customercompanies: [],
      isAllowDetail: false,
      isAllowVerification: false,
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')

        if (userMenus.includes('CUSTOMER_COMPANY_VERIFICATION')) {
          this.isAllowVerification = true
        }

        if (userMenus.includes('CUSTOMER_COMPANY_EDIT')) {
          this.isAllowDetail = true
        }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/t/customer/company', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.customercompanies = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      detailButtonClick (item) {
        this.$router.push({ name: 'CustomerCompanyDetail', params: { id: item.hash } })
      },

      verificationButtonClick () {
        this.$router.push({ name: 'CustomerCompaniesVerification' })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>
